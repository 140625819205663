import React from "react";
import { ComponentContainerMain } from "../container/component-container-main";
import { ComponentLightboxWrapper } from "./component-lightbox-wrapper";

interface IGridGalleryProps {
	title: string;
	pictureList: IPictureItem[];
	colCount: number;
}

export interface IPictureItem {
	path: string;
	orderId: number;
	altText: string;
	fullRow?: boolean;
}

export class GridGallery extends React.Component <IGridGalleryProps> {
	
	public constructor(props: IGridGalleryProps) {
		super(props);
		this.createPictureItems = this.createPictureItems.bind(this);
		this.createPicture = this.createPicture.bind(this);
	}
	
	public render() {
		return (
			<ComponentContainerMain title={this.props.title}>
				<ComponentLightboxWrapper>
					<section className="gallery-block grid-gallery">
						<div className="row">
							{this.createPictureItems(this.props.pictureList, this.props.colCount)}
						</div>
					</section>
				</ComponentLightboxWrapper>
			</ComponentContainerMain>
		);
	}
	
	/**
	 * Sorts array depending on orderId
	 *
	 * @param data Array of picture Items with src, orderId and alt-text
	 */
	private sortByOrder(data: IPictureItem[]): IPictureItem[] {
		return data.sort((a, b) => a.orderId - b.orderId);
	}
	
	/**
	 * Gets array of objects containing src, id and alt-text
	 *
	 * Sorts array depending on id
	 *
	 * Maps array to function to create JSX-Elements
	 *
	 * @param pictureList List of pictureData given through props
	 * @param colCount Number of wished columns given through props
	 */
	private createPictureItems(pictureList: IPictureItem[], colCount: number): JSX.Element[] {
		const sortedList: IPictureItem[] = this.sortByOrder(pictureList);
		return (
			sortedList.map(item => this.createPicture(item.path, item.altText, item.fullRow, colCount)));
	}
	
	/**
	 * Puts object data like src or alt-text into predefined image JSX-Element and returns it
	 *
	 * @param item Imported item (jpg,png...)
	 * @param altText Alternative Text to display
	 * @param colCount Number of wished columns given through props
	 */
	private createPicture(item: string, altText: string, fullRow: boolean | undefined, colCount: number): JSX.Element {
		let colCountString = "";
		switch (colCount) {
			case 2: {
				colCountString = "col-md-6 col-lg-6";
				break;
			}
			case 3: {
				colCountString = "col-md-6 col-lg-4";
				break;
			}
		}
		if (fullRow) {
			colCountString = "col-md-12 col-lg-12 item";
		}
		return (
			<div className={colCountString + " item"}>
				<a href={item}>
					<img className="img-fluid image scale-on-hover" src={item} alt={altText}/>
				</a>
			</div>
		);
	}
}

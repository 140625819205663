import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "./pictures/logo_with_text.png";
import logoSmall from "./pictures/new_logo_smaller_outline_no_background.png";

import { Link } from "react-router-dom";

export class ComponentNavbar extends React.Component<any, any> {
  public render() {
    return (
      <Navbar bg={"c_header"} expand={"md"} sticky={"top"} className={"navbar"} collapseOnSelect={true}>
        <Navbar.Brand>
          <Nav.Link as={Link} to={"/"}>
            <img
              src={logo}
              className={"d-none d-sm-inline-block align-top"}
              height={"50px"} alt={"bg design"}/>
            <img
              src={logoSmall}
              className={"d-inline-block align-top d-sm-none"}
              height={"40px"}
              alt={"bg design"}/>
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link eventKey={1}>
              <Link to={"/maerkte"}>märkte</Link>
            </Nav.Link>
            <NavDropdown title="materialien">
              <NavDropdown.Item>
                <Nav.Link as={Link} eventKey={2} to={"/materialien/porzellan"}>
                  porzellan
                </Nav.Link>
                <Nav.Link as={Link} eventKey={3} to={"/materialien/keramik"}>
                  keramik
                </Nav.Link>
                <Nav.Link as={Link} eventKey={4} to={"/materialien/schmuck"}>
                  schmuck
                </Nav.Link>
                <Nav.Link as={Link} eventKey={5} to={"/materialien/filz"}>
                  filz
                </Nav.Link>
                <Nav.Link as={Link} eventKey={6} to={"/materialien/stoff"}>
                  stoff
                </Nav.Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link eventKey={7}>
              <Link to={"/bestellen"}>bestellen</Link>
            </Nav.Link>
            <Nav.Link eventKey={8}>
              <Link to={"/bilder"}>bilder</Link>
            </Nav.Link>
            <NavDropdown title="über mich" id="basic-nav-dropdown">
              <NavDropdown.Item>
                <Nav.Link as={Link} eventKey={9} to={"/ueber-mich/vita"}>vita
                </Nav.Link>
                <Nav.Link  as={Link} eventKey={10} to={"/ueber-mich/presse"}>presse
                </Nav.Link>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

import React from "react";
import { ComponentContainerMain } from "../component/container/component-container-main";

export class PageNoMatch extends React.Component {
	public render() {
		return (
			<div>
				<ComponentContainerMain title={"Error 404"}>
					<h1 style={{textAlign: "center"}}>
						Diese Seite existiert leider nicht.
						<p>Probieren Sie doch einen Link aus der Menü-Leiste</p>
					</h1>
				</ComponentContainerMain>
			</div>
		);
	}
}

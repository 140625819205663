import React from "react";
import { Col, Row } from "react-bootstrap";
import "../../component/app.css";
import { ComponentContainerInner } from "../../component/container/component-container-inner";
import { ComponentContainerMain } from "../../component/container/component-container-main";
import { marktListData } from "../../component/markt-list/component-mark-list-items";
import { ComponentMarktList } from "../../component/markt-list/component-markt-list";
import marktBild from "./pictures/markt-picture.jpg";

export class MaerkteClass extends React.Component<any, any> {
	public render() {
		return (
			<ComponentContainerMain title={"märkte"}>
				<ComponentContainerInner>
					<Row>
						<Col className={"centerThis"}>
							<img className={"image"} src={marktBild} alt={"Bild von einem Kunsthandwerkermarkt"}/>
						</Col>
					</Row>
					<Row>
						<Col className={"centerThis"}>
							<ComponentMarktList marktItemList={marktListData} className={"pageMaerkteTable"}/>
						</Col>
					</Row>
				</ComponentContainerInner>
			</ComponentContainerMain>
		);
	}
}

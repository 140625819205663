export interface IMarktListItem {
	date: string;
	town: string;
	name: string;
	link: string;
	noLink?: boolean;
}

export const marktListData: IMarktListItem[] = [
	{
		date: "27.-28.5.23",
		town: "Oldenburg",
		name: "Nikolaimarkt",
		link: "https://nikolai-markt.de/"
	},
	{
		date: "16.-19.6.23",
		town: "München",
		name: "Stadtgründungsfest",
		link: "https://www.muenchen-online.de/veranstaltung/865-stadtgruendungsfest-muenchen-2023-385109/"
	},
	{
		date: "1.-2.7.23",
		town: "Maxlrain",
		name: "Trachtenmarkt",
		link: "https://www.markt-und-aktion.de/maerkte/trachten-handwerkermarkt-maxlrain.html"
	},
	{
		date: "8.7.23",
		town: "Staufen",
		name: "Kunsthandwerkermarkt",
		link: "https://www.kunsthandwerkermarkt.de/ausstellerverzeichnis-nach-namen"
	},
	{
		date: "21.-23.7.23",
		town: "Überlingen",
		name: "Promenadenfest",
		link: "https://www.ueberlingen-bodensee.de/promenadenfest"
	}
];

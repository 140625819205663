import React, { Component } from "react";
import { ComponentContainerPictureLayout } from "../../../component/container/component-container-picture-layout";
import { IPictureItem } from "../../../component/grid-gallery/grid-gallery";
import blumenKette from "./pictures/blumenKette.jpg";
import ovaleKette from "./pictures/ovaleKette.jpg";
import rosenKette from "./pictures/rosenKette.jpg";

const pictureList: IPictureItem[] = [
	{
		path: blumenKette,
		orderId: 2,
		altText: "blumen Kette ",
	},
	{
		path: ovaleKette,
		orderId: 3,
		altText: "ovale Kette ",
	},
	{
		path: rosenKette,
		orderId: 1,
		altText: "rosen Kette ",
	},
];

export class PageFilzSchmuck extends Component {
	public render() {
		return (
			<ComponentContainerPictureLayout title={"filzschmuck"} pictureList={pictureList} text={<React.Fragment/>} enableBottomPicture={true}/>
		);
	}
}

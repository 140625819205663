import React from "react";
import PropTypes from "prop-types";
// @ts-ignore
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

export class ComponentLightboxWrapper extends React.Component {
	
	public lightboxOptions: any = {
		settings: {
			overlayColor: "rgba(214, 214, 214, 0.9)",
			disablePanzoom: true,
			slideAnimationType: "none",
			slideSpringValues: [300, 200],
		},
		buttons: {
			iconColor: "rgba(255, 236, 217, 1)",
			backgroundColor: "#FF6600",
			showAutoplayButton: false,
			showCloseButton: true,
			showDownloadButton: false,
			showFullscreenButton: false,
			showNextButton: true,
			showPrevButton: true,
		},
		thumbnails: {
			showThumbnails: false,
		},
		caption: {
			showCaption: false,
		}
	};
	
	public render() {
		return (
			<SimpleReactLightbox>
				<SRLWrapper options={this.lightboxOptions}>
					{this.props.children}
				</SRLWrapper>
			</SimpleReactLightbox>
		);
	}
	
	propTypes = {
		children: PropTypes.node.isRequired
	};
}


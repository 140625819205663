import React from "react";
import { Button, Row } from "react-bootstrap";
import { ComponentContainerInner } from "../../component/container/component-container-inner";
import { ComponentContainerMain } from "../../component/container/component-container-main";
import { IPictureItem } from "../../component/grid-gallery/grid-gallery";
import { ComponentHeadlineSecondary } from "../../component/headline/component-headlines";
import ElegantTitelbildBroscheGrau from "./pictures/ElegantTitelbildBroscheGrau.jpg";
import EleganzBroscheGrau from "./pictures/EleganzBroscheGrau.jpg";
import EleganzBroscheRot from "./pictures/EleganzBroscheRot.jpg";
import graueBrosche from "./pictures/graueBrosche.jpg";
import HutBroscheRot from "./pictures/HutBroscheRot.jpg";
import HutGraueBrosche from "./pictures/HutGraueBrosche.jpg";
import roteBrosche from "./pictures/roteBrosche.jpg";
import verfilztUndZugenaeht1 from "./pictures/verfilztUndZugenaeht1.jpg";
import verfilztUndZugenaeht2 from "./pictures/verfilztUndZugenaeht2.jpg";
import verfilztUndZugenaeht3 from "./pictures/verfilztUndZugenaeht3.jpg";

const verFilztPictures: IPictureItem[] = [
	
	{
		path: verfilztUndZugenaeht1,
		orderId: 1,
		altText: "verfilzt Und Zugenaeht1",
	},
	{
		path: verfilztUndZugenaeht2,
		orderId: 2,
		altText: "verfilzt Und Zugenaeht2",
	},
	{
		path: verfilztUndZugenaeht3,
		orderId: 3,
		altText: "verfilzt Und Zugenaeht3",
	},
];

const EleganzePicturesGrau: IPictureItem[] = [
	{
		path: ElegantTitelbildBroscheGrau,
		orderId: 3,
		altText: " Elegant Titelbild Brosche Grau",
	},
	{
		path: EleganzBroscheGrau,
		orderId: 4,
		altText: " Eleganz Brosche Grau",
	},
	{
		path: HutGraueBrosche,
		orderId: 2,
		altText: " Hut Graue Brosche",
	},
	{
		path: graueBrosche,
		orderId: 1,
		altText: "graue Brosche",
	},
];

const EleganzePicturesRot: IPictureItem[] = [
	{
		path: EleganzBroscheRot,
		orderId: 3,
		altText: " Eleganz Brosche Rot",
	},
	
	{
		path: HutBroscheRot,
		orderId: 2,
		altText: " Hut Brosche Rot",
	},
	
	{
		path: roteBrosche,
		orderId: 1,
		altText: "rote Brosche",
	},
];

export class PagePresse extends React.Component {
	public render() {
		return (
			<ComponentContainerMain title={"presse"}>
				<ComponentContainerInner>
					<Row>
						<ComponentHeadlineSecondary title={"verFilzt und zugeNäht"}/>
						<p>
							Portrait Birgit Gillen <br/>
							erschienen in der Zeitschrift &quotverFilzt Und zugeNäht&quot Heft 25
						</p>
					</Row>
					<Row className={"verfilzt"}>
						{this.createPictures(verFilztPictures)}
					</Row>
					<Row>
						<Button id={"linkButton"} style={{marginTop: "10px"}}>
							<a href="Vernetzender_Filz.pdf" download="vernetzerFilz.pdf" target="_blank">Download Link</a>
						</Button>
					</Row>
					<Row>
						<div id="textSeperator"/>
					</Row>
					<Row>
						<ComponentHeadlineSecondary title={"Elégance"}/>
						<p>
							Elégance 2006 / 2007 Seite 18 Photos: Lutz
						</p>
					</Row>
					<Row className={"verfilzt"}>
						{this.createPictures(EleganzePicturesGrau)}
					</Row>
					<Row>
						<div id="textSeperator"/>
					</Row>
					<Row>
						<p>
							Elégance 2006 / 2007 Seite 57 Photos: Sammy / Georges
						</p>
					</Row>
					<Row className={"verfilzt"}>
						{this.createPictures(EleganzePicturesRot)}
					</Row>
				</ComponentContainerInner>
			</ComponentContainerMain>
		);
	}
	
	private createPictures(data: IPictureItem[]): JSX.Element[] {
		data = this.sortByOrder(data);
		return data.map(item => this.createPicture(item));
	}
	
	private createPicture(item: IPictureItem): JSX.Element {
		return (
			<img src={item.path}
				 alt={item.altText}/>
		);
	}
	
	/**
	 * Sorts array depending on orderId
	 *
	 * @param data Array of picture Items with src, orderId and alt-text
	 */
	private sortByOrder(data: IPictureItem[]): IPictureItem[] {
		return data.sort((a, b) => a.orderId - b.orderId);
	}
}

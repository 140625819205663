import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";

interface IComponentContainerMainProps {
	title?: string;
}

export class ComponentContainerMain extends Component <IComponentContainerMainProps> {
	
	public constructor(props: IComponentContainerMainProps) {
		super(props);
	}
	
	public render() {
		return (
			<Container id={"containerCSS"}>
				<ComponentContainerGridWidth id={"mainContainerCol"}>
					<Row noGutters={false}>
						<Col id="mainHeadline">
							{this.props.title}
						</Col>
					</Row>
					<Row className={"centerThisHorizontal"} noGutters={true}>
						<Col>
							{this.props.children}
						</Col>
					</Row>
				</ComponentContainerGridWidth>
			</Container>
		);
	}
}

interface IComponentContainerGridWidth {
	id?: string;
}

export class ComponentContainerGridWidth extends React.Component <IComponentContainerGridWidth> {
	public render() {
		return (
			<Col lg={12} xl={11} id={this.props.id}>
				{this.props.children}
			</Col>
		);
	}
}

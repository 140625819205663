import React from "react";
import { Col, Row } from "react-bootstrap";
import { ComponentContainerInner } from "../../component/container/component-container-inner";
import { ComponentContainerMain } from "../../component/container/component-container-main";
import profilbild from "./pictures/profilBild.jpg";

export class VitaClass extends React.Component<any, any> {
	public render() {
		return (
			<ComponentContainerMain title={"vita"}>
				<ComponentContainerInner>
					<Row>
						<Col style={{display: "flex", justifyContent: "end", alignItems: "start"}}>
							<img src={profilbild} alt={"Profilbild von Birgit Gillen"}/>
						</Col>
						<Col style={{display: "flex", justifyContent: "start"}}>
							<div>
								<p className="bodytext">Jahrgang 1967, Bremen</p>
								<p className="bodytext">Hotelfachausbildung</p>
								<p className="bodytext">Malschule Bojer, M&uuml;nchen</p>
								<p className="bodytext">1990-1995 Studium Produktdesign FH Krefeld Porzellan und Keramik mit Abschlu&szlig; Diplom Designerin bei Prof. Wolf
									Karnagel</p>
								<p className="bodytext">1995 Werkstattbeteiligung Joanne Windaus, D&uuml;sseldorf</p>
								<p className="bodytext">1995 Geburt Sohn Jacob</p>
								<p className="bodytext">1996-1998 Ausbildung zur NLP Trainerin bei Hermann M&uuml;ller-Walbrodt, Institut de Myhl</p>
								<p className="bodytext">1999 Geburt Sohn Julius</p>
								<p className="bodytext">seit 2001 Freiberufliche, gestalterische T&auml;tigkeit im Jakob-B&uuml;chel-Haus, Aachen-Walheim</p>
								<p className="bodytext">2007-2014 Ladenlokal<br/> &quot;Filz und DesignWerkstatt&quot; in Roetgen</p>
								<p className="bodytext">Teilnahme an Ausstellungen im Bereich Kunsthandwerk und Design</p>
							</div>
						</Col>
					</Row>
				</ComponentContainerInner>
			</ComponentContainerMain>
		);
	}
}

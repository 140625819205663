import React from "react";

interface IComponentHeadlineSecondaryProps {
	title: string | undefined;
}

export class ComponentHeadlineSecondary extends React.Component <IComponentHeadlineSecondaryProps> {
	
	public constructor(props: IComponentHeadlineSecondaryProps) {
		super(props);
	}
	
	public render() {
		return (
			<div className="container bg-c_primary" style={{paddingLeft: "0px"}}>
				<div id="secondaryHeadline">
					{this.props.title}
				</div>
			</div>
		);
	}
}

import React, { Component } from "react";
import { ComponentContainerPictureLayout } from "../../../component/container/component-container-picture-layout";
import { IPictureItem } from "../../../component/grid-gallery/grid-gallery";
import gemischteSchalen from "./pictures/gemischteSchalen.jpg";
import schaleGelb from "./pictures/schaleGelb.jpg";
import schalenMitObst from "./pictures/schalenMitObst.jpg";

const pictureList: IPictureItem[] = [
	{
		path: gemischteSchalen,
		altText: "gemischteSchalen",
		orderId: 1
	},
	{
		path: schalenMitObst,
		altText: "schalenMitObst",
		orderId: 2,
	},
	{
		path: schaleGelb,
		altText: "gelbeSchale",
		orderId: 3
	}
];

const text: JSX.Element = (
	<React.Fragment>
		<p>Die Form der Seeigel gab die Idee zu den hohlgegossenen Schalen</p>
	</React.Fragment>
);

export class SchalenClass extends Component {
	public render() {
		return (
			<ComponentContainerPictureLayout title={"schalen"} pictureList={pictureList} text={text} enableBottomPicture={true}/>
		);
	}
}

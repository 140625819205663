import React, { Component } from "react";
import { GridGallery } from "../../component/grid-gallery/grid-gallery";
import { pageBilderPictureList } from "./page-bilder-picture-list";

export class BilderClass extends Component {
	public render() {
		return (
			<GridGallery pictureList={pageBilderPictureList} title={"Bilder"} colCount={3}/>
		);
	}
}

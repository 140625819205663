import React from "react";
import { Col } from "react-bootstrap";

interface IComponentContainerInnerProps {
	id?: string;
}

/**
 * Inner Container for text, pictures etc.
 *
 * Hierachy is ContainerMain > ContainerInner
 */
export class ComponentContainerInner extends React.Component <IComponentContainerInnerProps> {
	
	public render() {
		return (
			<div className={"centerThisHorizontal"}>
				<Col xs={12} sm={12} md={9} lg={8} xl={8} id={this.props.id}>
					{this.props.children}
				</Col>
			</div>
		);
	}
}

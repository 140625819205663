import React from "react";
import { GridGallery, IPictureItem } from "../../../component/grid-gallery/grid-gallery";
import aufhaenger from "./pictures/aufhaenger.jpg";
import bondeaux from "./pictures/bondeaux.png";
import handStulpen from "./pictures/handStulpen.jpg";
import kombination from "./pictures/kombination.png";
import kombination2 from "./pictures/kombination2.png";
import kombination3 from "./pictures/kombination3.png";
import kombinationWadenwaermer from "./pictures/kombinationWadenwaermer.jpg";
import marktstand from "./pictures/marktstand.jpg";
import model2 from "./pictures/model2.png";
import model3 from "./pictures/model3.jpg";
import pulsWaermer from "./pictures/pulsWaermer.jpg";
import pulsWaermer2 from "./pictures/pulsWaermer2.jpg";
import schal from "./pictures/schal.jpg";
import schal4 from "./pictures/schal4.jpg";
import schal5 from "./pictures/schal5.png";
import schal6 from "./pictures/schal6.jpg";
import schalUndSchirm from "./pictures/schalUndSchirm.jpg";
import schalUndTunika from "./pictures/schalUndTunika.jpg";
import tunika from "./pictures/tunika.jpg";
import vierModels from "./pictures/vierModels.jpg";
import filzBlumeRosaBlau from "./pictures/filz-blumen-rosa-blau.jpg";
import marktStand1 from "./pictures/markt-stand-1.jpg";
import modelBlauRosa from "./pictures/model-blau-rosa.jpg";
import modelFahrrad1 from "./pictures/model-fahrrad-1.jpg";
import modelGruen from "./pictures/model-gruen-1.jpg";
import modelBlaueWand1 from "./pictures/modell-blaue-wand-1.jpg";
import modelBlaueWand2 from "./pictures/modell-blaue-wand-2.jpg";
import modelBlaueWand3 from "./pictures/modell-blaue-wand-3.jpg";
import stand2 from "./pictures/stand2.jpg";
import trockenFilz from "./pictures/trocken-filz.jpg";

const pictureList: IPictureItem[] = [
	{
		path: aufhaenger,
		orderId: 2,
		altText: "aufhaenger",
	},
	{
		path: bondeaux,
		orderId: 13,
		altText: "bondeaux",
	},
	{
		path: handStulpen,
		orderId: 7,
		altText: "hand Stulpen",
	},
	{
		path: kombination,
		orderId: 17,
		altText: "kombination",
	},
	{
		path: kombination2,
		orderId: 14,
		altText: "kombination2",
	},
	{
		path: kombination3,
		orderId: 15,
		altText: "kombination3",
	},
	{
		path: kombinationWadenwaermer,
		orderId: 4,
		altText: "kombination Wadenwaermer",
	},
	{
		path: marktstand,
		orderId: 3,
		altText: "marktstand",
	},
	{
		path: model2,
		orderId: 18,
		altText: "model2",
	},
	{
		path: model3,
		orderId: 11,
		altText: "model3",
	},
	{
		path: pulsWaermer,
		orderId: 8,
		altText: "puls Waermer",
	},
	{
		path: pulsWaermer2,
		orderId: 14,
		altText: "puls Waermer2",
	},
	{
		path: schal,
		orderId: 10,
		altText: "schal",
	},
	{
		path: schal4,
		orderId: 18,
		altText: "schal4",
	},
	{
		path: schal5,
		orderId: 16,
		altText: "schal5",
	},
	{
		path: schal6,
		orderId: 9,
		altText: "schal6",
	},
	{
		path: schalUndSchirm,
		orderId: 6,
		altText: "schal Und Schirm",
	},
	{
		path: schalUndTunika,
		orderId: 11,
		altText: "schal Und Tunika",
	},
	{
		path: tunika,
		orderId: 1,
		altText: "tunika",
	},
	{
		path: vierModels,
		orderId: 5,
		altText: "vier Models",
	},
	{
		path: filzBlumeRosaBlau,
		orderId: 19,
		altText: "filz Blume Rosa Blau",
	},
	{
		path: marktStand1,
		orderId: 20,
		altText: "markt Stand",
	},
	{
		path: modelBlauRosa,
		orderId: 21,
		altText: "Blau Rosa Oberteil",
	},
	{
		path: modelFahrrad1,
		orderId: 22,
		altText: "grünes Oberteil mit Fahrrad Lenker",
	},
	{
		path: modelGruen,
		orderId: 24,
		altText: "grünes Oberteil",
	},
	{
		path: modelBlaueWand1,
		orderId: 25,
		altText: "Model vor blauer Wand",
	},
	{
		path: modelBlaueWand2,
		orderId: 26,
		altText: "Model vor blauer Wand",
	},
	{
		path: modelBlaueWand3,
		orderId: 27,
		altText: "Model vor blauer Wand",
	},
	{
		path: stand2,
		orderId: 28,
		altText: "Markstand",
	},
	{
		path: trockenFilz,
		orderId: 26,
		altText: "Filzblume aus unverfilzter Wolle",
	},
];

export class StoffClass extends React.Component {
	public render() {
		return (
			<GridGallery title={"stoff"} pictureList={pictureList} colCount={3}/>
		);
	}
}

import React from "react";
import { ComponentContainerInner } from "../component/container/component-container-inner";
import { ComponentContainerMain } from "../component/container/component-container-main";

export class BestellenClass extends React.Component {
	public render() {
		return (
			<ComponentContainerMain title={"bestellen"}>
				<ComponentContainerInner>
					<div style={{textAlign: "right"}}>
						<p>Wenn meine homepage Ihr Interesse an meinen Produkten geweckt haben sollte:<br/>Rufen Sie mich an oder schicken Sie mir eine Nachricht:</p>
						<p>birgit gillen<br/>bg design<br/>Weiherstrasse 13<br/><br/>52156 Monschau<br/>mobil 0175 1575195</p>
						<p><a>info(at)birgitgillen&middot;de</a></p>
						<p>&nbsp; </p>
						<p>webDesign: <a>matthias(at)greiner-petter&middot;de</a>
							<br/>Realisation mit React: <a>jacob&middot;gillen(at)posteo&middot;de</a></p>
					</div>
				</ComponentContainerInner>
			</ComponentContainerMain>
		);
	}
}

import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { ComponentContainerGridWidth } from "../container/component-container-main";
import logo1 from "./pictures/logo_with_text_no_background.png";
import logo2 from "./pictures/new_logo_smaller_outline_no_background.png";

export class ComponentFooter extends React.Component<any, any> {
	
	public render() {
		return (
			<Container id={"footerContainer"}>
				<ComponentContainerGridWidth id={"mainFooterCol"}>
					<Row noGutters={true}>
						<Col>
							<Row className={"centerThis"}>
								<Col sm={3} className={"centerThis"}>
									<Nav.Link as={Link} to={"/"}>
										<img
											src={logo1}
											className="d-none d-xl-inline-block"
											alt="logo von birgit gillen"
											id={"footerLogo"}/>
										<img
											src={logo2}
											className="d-inline-block d-xl-none"
											alt="logo von birgit gillen"
											id={"footerLogo"}/>
									</Nav.Link>
								</Col>
								<Col sm={3} style={{flexDirection: "column"}} className={"centerThis"}>
									<div className="d-sm-none" id="seperator"/>
									<Nav.Link id="footerlink" as={Link} to={"/impressum"}>impressum</Nav.Link>
									<div className="d-sm-none" id="seperator"/>
								</Col>
								<Col sm={3} className={"centerThis text-light"} style={{flexDirection: "column"}}>
									<div style={{fontSize: "1rem", textAlign: "center"}}>kontakt <br/>mail (at) birgitgillen.de</div>
									<div className="d-sm-none" id="seperator"/>
								</Col>
								<Col sm={3} style={{flexDirection: "column"}} className={"centherThis text-light"}>
									<div style={{fontSize: "1rem", textAlign: "center"}}> folge mir</div>
									<div className={"centerThisHorizontal"}>
										<a href="https://www.instagram.com/birgitgillen/"  target="_blank" rel={"noreferrer"}>
											<InstagramIcon fontSize={"large"} color={"inherit"}/>
										</a>
										<a href={"https://www.facebook.com/birgit.gillen.3"} target="_blank" rel={"noreferrer"}>
											<FacebookIcon fontSize={"large"}/>
										</a>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</ComponentContainerGridWidth>
			</Container>
		);
	}
}

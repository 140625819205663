import React from "react";
import { Col, Row } from "react-bootstrap";
import { ComponentContainerMain } from "../../component/container/component-container-main";
import { ComponentHeadlineSecondary } from "../../component/headline/component-headlines";
import { marktListData } from "../../component/markt-list/component-mark-list-items";
import { ComponentMarktList } from "../../component/markt-list/component-markt-list";
import tasche from "./pictures/filzTasche.jpg";
import schoeneDinge from "./pictures/schoene-dinge.jpg";

export class LandingPage extends React.Component<any, any> {
  public render() {
    return (
      <ComponentContainerMain>
        <Row>
          <Col md={6}>
            <Row className={"schoeneDinge"}>
              <a href={"https://schöne-dinge-unterwegs.de/"} target={"_blank"} rel="noreferrer">
                <img src={schoeneDinge} style={{ width: "50px" }} className={"image"}
                     alt={"logo schoene Dinge unterwegs"}/>
              </a>
              <a href={"https://schöne-dinge-unterwegs.de/"} target={"_blank"} rel="noreferrer">Hier geht es zum
                Kunsthandwerk-Buchprojekt<br/> &quot;Schöne Dinge Unterwegs&quot;
              </a>
            </Row>
            <Row className={"centerThis"}>
              <img className={"image"} src={tasche} alt={"filz tasche"}/>
            </Row>
          </Col>
          <Col md={6}>
            <ComponentHeadlineSecondary title={"messen märkte ausstellungen"}/>
            <ComponentMarktList marktItemList={marktListData}/>
          </Col>
        </Row>
      </ComponentContainerMain>
    );
  }
}

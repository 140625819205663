import React, { Component } from "react";
import { ComponentContainerPictureLayout } from "../../../component/container/component-container-picture-layout";
import { IPictureItem } from "../../../component/grid-gallery/grid-gallery";
import babyKlamotten from "./pictures/babyKlamotten.jpg";
import babySchuhe from "./pictures/babySchuhe.jpg";
import spieluhren from "./pictures/spieluhren.jpg";

const pictureList: IPictureItem[] = [
	{
		path: babyKlamotten,
		orderId: 1,
		altText: "baby Klamotten ",
	},
	{
		path: babySchuhe,
		orderId: 3,
		altText: "baby Schuhe ",
	},
	{
		path: spieluhren,
		orderId: 2,
		altText: "spieluhren ",
	},
];

export class KinderFilzClass extends Component <any, any> {
	public render() {
		return (
			<ComponentContainerPictureLayout title={"kinderfilz"} pictureList={pictureList} text={<React.Fragment/>} enableBottomPicture={true}/>
		);
	}
}

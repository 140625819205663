import React from "react";
import { ComponentContainerPictureLayout } from "../../../component/container/component-container-picture-layout";
import { IPictureItem } from "../../../component/grid-gallery/grid-gallery";
import gestapelteTassen from "./pictures/gestapelteTassen.jpg";
import komplettesSetOhneTablett from "./pictures/komplettesSetOhneTablett.jpg";
import komplettesTablettSet from "./pictures/komplettesTablettSet.jpg";
import tassen from "./pictures/tassen.jpg";

const pictureList: IPictureItem[] = [
	{
		path: gestapelteTassen,
		altText: "gestapeltetassen",
		orderId: 1,
	},
	{
		path: tassen,
		altText: "tassen",
		orderId: 2,
	},
	{
		path: komplettesSetOhneTablett,
		altText: "komplettesSetOhneTablett",
		orderId: 3
	},
	{
		path: komplettesTablettSet,
		altText: "komplettesTablettSet",
		orderId: 4
	}
];

const text: JSX.Element = (
	<div>
		<p className="bodytext">Diplomarbeit mit dem Thema: &ldquo;Gestalten eines Systemgeschirrs unter
			Ber&uuml;cksichtigung der Verwendung im Tablettservice&rdquo;</p>
		<p className="bodytext">Realisation der Prototypen w&auml;hrend eines Praktikums bei und mit der Fa.
			Hutschenreuther 1995</p>
		<p className="bodytext">Ankauf des Entwurfes durch die Firma temp-rite, Bremen</p>
	</div>
);

export class SystemGeschirrClass extends React.Component {
	public render() {
		return (
			<ComponentContainerPictureLayout title={"system geschirr"} pictureList={pictureList} text={text} enableBottomPicture={true} maxWidthRightPictureColumn={"160px"}/>
		);
	}
}

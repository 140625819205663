import { ComponentClass } from "react";
import { BilderClass } from "../pages/bilder/page-bilder";
import { PageImpressum } from "../pages/impressum/page-impressum";
import { LandingPage } from "../pages/landing-page/page-landing-page";
import { MaerkteClass } from "../pages/maerkte/page-maerkte";
import { FilzClass } from "../pages/materialien/filz/page-filz";
import { PageFilzDekoration } from "../pages/materialien/filz/page-filz-dekoration";
import { KinderFilzClass } from "../pages/materialien/filz/page-filz-kinder";
import { PageFilzSchmuck } from "../pages/materialien/filz/page-filz-schmuck";
import { KeramikClass } from "../pages/materialien/keramik/page-keramik";
import { MobilesGeschirrClass } from "../pages/materialien/keramik/page-keramik-mobiles-geschirr";
import { SchalenClass } from "../pages/materialien/keramik/page-keramik-schalen";
import { PorzellanClass } from "../pages/materialien/porzellan/page-porzellan";
import { PagePorzellanKaffee } from "../pages/materialien/porzellan/page-porzellan-kaffee";
import { MinisystemGeschirrClass } from "../pages/materialien/porzellan/page-porzellan-mini-system-geschirr";
import { SystemGeschirrClass } from "../pages/materialien/porzellan/page-porzellan-system-geschirr";
import { PageSchmuck } from "../pages/materialien/schmuck/page-schmuck";
import { StoffClass } from "../pages/materialien/stoff/page-stoff";
import { BestellenClass } from "../pages/page-bestellen";
import { KontaktClass } from "../pages/page-kontakt";
import { PagePresse } from "../pages/ueber-mich/page-presse";
import { VitaClass } from "../pages/ueber-mich/page-vita";

export interface IRouteList {
	path: string;
	title: string;
	component: ComponentClass;
	originalBreadcrumbName: string;
	deactivateLink?: boolean;
}

export const routeList: IRouteList[] = [
	{
		path: "/maerkte",
		title: "märkte",
		component: MaerkteClass,
		originalBreadcrumbName: "maerkte"
	},
	{
		path: "/materialien/porzellan",
		title: "porzellan",
		component: PorzellanClass,
		originalBreadcrumbName: "porzellan",
	},
	{
		path: "/materialien/porzellan/kaffee",
		title: "kaffee",
		component: PagePorzellanKaffee,
		originalBreadcrumbName: "kaffee",
	},
	{
		path: "/materialien/porzellan/system-geschirr",
		title: "system geschirr",
		component: SystemGeschirrClass,
		originalBreadcrumbName: "system-geschirr"
	},
	{
		path: "/materialien/porzellan/minisystem-geschirr",
		title: "minisystem geschirr",
		component: MinisystemGeschirrClass,
		originalBreadcrumbName: "minisystem-geschirr",
	},
	{
		path: "/materialien/keramik",
		title: "keramik",
		component: KeramikClass,
		originalBreadcrumbName: "keramik",
	},
	{
		path: "/materialien/keramik/mobiles-geschirr",
		title: "mobiles geschirr",
		component: MobilesGeschirrClass,
		originalBreadcrumbName: "mobiles-geschirr",
	},
	{
		path: "/materialien/keramik/schalen",
		title: "schalen",
		component: SchalenClass,
		originalBreadcrumbName: "schalen"
	},
	{
		path: "/materialien/schmuck",
		title: "schmuck",
		component: PageSchmuck,
		originalBreadcrumbName: "schmuck"
	},
	{
		path: "/materialien/filz",
		title: "filz",
		component: FilzClass,
		originalBreadcrumbName: "filz"
	},
	{
		path: "/materialien/filz/filzschmuck",
		title: "filzschmuck",
		component: PageFilzSchmuck,
		originalBreadcrumbName: "filzschmuck"
	},
	{
		path: "/materialien/filz/filzdekoration",
		title: "filzdekoration",
		component: PageFilzDekoration,
		originalBreadcrumbName: "filzdekoration"
	},
	{
		path: "/materialien/filz/kinderfilz",
		title: "kinderfilz",
		component: KinderFilzClass,
		originalBreadcrumbName: "kinderfilz"
	},
	{
		path: "/materialien/stoff",
		title: "stoff",
		component: StoffClass,
		originalBreadcrumbName: "stoff"
	},
	{
		path: "/bestellen",
		title: "bestellen",
		component: BestellenClass,
		originalBreadcrumbName: "bestellen"
	},
	{
		path: "/bilder",
		title: "bilder",
		component: BilderClass,
		originalBreadcrumbName: "bilder"
	},
	{
		path: "/ueber-mich/vita",
		title: "vita",
		component: VitaClass,
		originalBreadcrumbName: "vita"
	},
	{
		path: "/ueber-mich/presse",
		title: "presse",
		component: PagePresse,
		originalBreadcrumbName: "presse"
	},
	{
		path: "/ueber-mich/kontakt",
		title: "kontakt",
		component: KontaktClass,
		originalBreadcrumbName: "kontakt"
	},
	{
		path: "/materialien",
		title: "materialien",
		component: LandingPage,
		originalBreadcrumbName: "materialien",
		deactivateLink: true,
	},
	{
		path: "/ueber-mich",
		title: "über mich",
		component: LandingPage,
		originalBreadcrumbName: "ueber-mich",
		deactivateLink: true,
	},
	{
		path: "/impressum",
		title: "impressum",
		component: PageImpressum,
		originalBreadcrumbName: "impressum",
	}
];

import React, { Component } from "react";
import { ComponentContainerButtonsLayout, IButtonData } from "../../../component/container/component-container-buttons-layout";
import bild from "./pictures/arbeitsstudieKaffetasse.jpg";

const linkPrefix: string = "/materialien/porzellan";

const porzellanButtonData: IButtonData[] = [
	{
		text: "kaffee",
		path: linkPrefix + "/kaffee"
	},
	{
		text: "system geschirr",
		path: linkPrefix + "/system-geschirr"
	},
	{
		text: "minisystem geschirr",
		path: linkPrefix + "/minisystem-geschirr"
	}
];

export class PorzellanClass extends Component<any, any> {
	public render() {
		return (
			<ComponentContainerButtonsLayout buttonData={porzellanButtonData} title={"porzellan"}>
				<img className={"responsiveImg"} src={bild} alt={"Arbeitsstudie Kaffetasste"}/>
			</ComponentContainerButtonsLayout>
		);
	}
}

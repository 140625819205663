import React, { Component } from "react";
import { ComponentContainerPictureLayout } from "../../../component/container/component-container-picture-layout";
import { IPictureItem } from "../../../component/grid-gallery/grid-gallery";
import schalenUndTeller from "./pictures/schalenUndTeller.jpg";

const pictureList: IPictureItem[] = [
	{
		path: schalenUndTeller,
		altText: "schalenUndTeller",
		orderId: 1
	}
];

const text: JSX.Element = (
	<React.Fragment>
		<div className="sp3">
			<div id="c16" className="csc-default">
				<div className="csc-textpic csc-textpic-intext-right">
					<div className="csc-textpic-text">
						<p className="bodytext">Wenig Teile decken eine gro&szlig;e Bandbreite an Nutzungs- m&ouml;glichkeiten ab.</p>
					</div>
				</div>
			</div>
			<div id="c46" className="csc-default">
				<p className="bodytext">Im Vordergrund der Gestaltung stehen<br/>
					Funktionalit&auml;t im Gebrauch, Rationalit&auml;t<br/>
					in der Produktion und Zeitlosigkeit der<br/>Formensprache
				</p>
			</div>
			<br/>
			<div className="dotline">&nbsp;</div>
		</div>
		<div className="cl">&nbsp;</div>
	</React.Fragment>
);

export class MinisystemGeschirrClass extends Component {
	public render() {
		return (
			<ComponentContainerPictureLayout title={"mini system geschirr"} pictureList={pictureList} text={text} enableBottomPicture={false}/>
		);
	}
}

import { IPictureItem } from "../../component/grid-gallery/grid-gallery";
import babyklamotten from "./pictures/babyklamotten.jpg";
import befilzteStulpe from "./pictures/befilzteStulpe.jpg";
import blaueFilzKette from "./pictures/blaueFilzKette.jpg";
import broscheMitKnospe from "./pictures/broscheMitKnospe.jpg";
import broschPuschelKette from "./pictures/broschPuschelKette.jpg";
import filzBlume3 from "./pictures/filzBlume3.jpg";
import filzBlume4 from "./pictures/filzBlume4.jpg";
import filzBlumen1 from "./pictures/filzBlumen1.jpg";
import filzBlumen2 from "./pictures/filzBlumen2.jpg";
import filzBlumen5 from "./pictures/filzBlumen5.jpg";
import filzBlumen6 from "./pictures/filzBlumen6.jpg";
import filzBlumenKette from "./pictures/filzBlumenKette.jpg";
import filzHausschuhe from "./pictures/filzHausschuhe.jpg";
import filzRing2 from "./pictures/filzRing2.jpg";
import filzRing3 from "./pictures/filzRing3.jpg";
import filzRinge from "./pictures/filzRinge.jpg";
import filzRinge4 from "./pictures/filzRinge4.jpg";
import filzRosenKette from "./pictures/filzRosenKette.jpg";
import filzSchal from "./pictures/filzSchal.jpg";
import filzTasse from "./pictures/filzTasse.jpg";
import gelbeSchalen from "./pictures/gelbeSchalen.jpg";
import glasKette from "./pictures/glasKette.jpg";
import glasKette2 from "./pictures/glasKette2.jpg";
import glasPerlenKette from "./pictures/glasPerlenKette.jpg";
import glasschmuck from "./pictures/glasschmuck.jpg";
import graueFilzKette from "./pictures/graueFilzKette.jpg";
import graueSteinFilzKette from "./pictures/graueSteinFilzKette.jpg";
import grauFilzKette from "./pictures/grauFilzKette.jpg";
import grauOvalDilberKette from "./pictures/grauOvalDilberKette.jpg";
import kartenmotiv from "./pictures/kartenmotiv.jpg";
import kroenchen from "./pictures/kroenchen.jpg";
import nadelkissen from "./pictures/nadelkissen.jpg";
import ohrringe2 from "./pictures/ohrringe2.jpg";
import osterDeko from "./pictures/osterDeko.jpg";
import puschelRinge from "./pictures/puschelRinge.jpg";
import rosaFilzKette from "./pictures/rosaFilzKette.jpg";
import schal from "./pictures/schal.jpg";
import schalenBlau from "./pictures/schalenBlau.jpg";
import stulpen from "./pictures/stulpen.jpg";
import uhrenkette1 from "./pictures/uhrenkette1.jpg";
import uhrenkette2 from "./pictures/uhrenkette2.jpg";
import verschiedeneKetten from "./pictures/verschiedeneKetten.jpg";

export const pageBilderPictureList: IPictureItem[] = [
	{
		path: babyklamotten,
		orderId: 41,
		altText: "babyklamotten",
	},
	{
		path: befilzteStulpe,
		orderId: 8,
		altText: "befilzteStulpe",
	},
	{
		path: blaueFilzKette,
		orderId: 3,
		altText: "blaueFilzKette",
	},
	{
		path: broscheMitKnospe,
		orderId: 25,
		altText: "broscheMitKnospe",
	},
	{
		path: broschPuschelKette,
		orderId: 38,
		altText: "broschPuschelKette",
	},
	{
		path: filzBlume3,
		orderId: 5,
		altText: "filzBlume3",
	},
	{
		path: filzBlume4,
		orderId: 14,
		altText: "filzBlume4",
	},
	{
		path: filzBlumen1,
		orderId: 31,
		altText: "filzBlumen1",
	},
	{
		path: filzBlumen2,
		orderId: 27,
		altText: "filzBlumen2",
	},
	{
		path: filzBlumen5,
		orderId: 6,
		altText: "filzBlumen5",
	},
	{
		path: filzBlumen6,
		orderId: 18,
		altText: "filzBlumen6",
	},
	{
		path: filzBlumenKette,
		orderId: 36,
		altText: "filzBlumenKette",
	},
	{
		path: filzHausschuhe,
		orderId: 33,
		altText: "filzHausschuhe",
	},
	{
		path: filzRing2,
		orderId: 30,
		altText: "filzRing2",
	},
	{
		path: filzRing3,
		orderId: 29,
		altText: "filzRing3",
	},
	{
		path: filzRinge,
		orderId: 40,
		altText: "filzRinge",
	},
	{
		path: filzRinge4,
		orderId: 26,
		altText: "filzRinge4",
	},
	{
		path: filzRosenKette,
		orderId: 32,
		altText: "filzRosenKette",
	},
	{
		path: filzSchal,
		orderId: 22,
		altText: "filzSchal",
	},
	{
		path: filzTasse,
		orderId: 21,
		altText: "filzTasse",
	},
	{
		path: gelbeSchalen,
		orderId: 19,
		altText: "gelbeSchalen",
	},
	{
		path: glasKette,
		orderId: 17,
		altText: "glasKette",
	},
	{
		path: glasKette2,
		orderId: 37,
		altText: "glasKette2",
	},
	{
		path: glasPerlenKette,
		orderId: 35,
		altText: "glasPerlenKette",
	},
	{
		path: glasschmuck,
		orderId: 13,
		altText: "glasschmuck",
	},
	{
		path: graueFilzKette,
		orderId: 16,
		altText: "graueFilzKette",
	},
	{
		path: graueSteinFilzKette,
		orderId: 15,
		altText: "graueSteinFilzKette",
	},
	{
		path: grauFilzKette,
		orderId: 28,
		altText: "grauFilzKette",
	},
	{
		path: grauOvalDilberKette,
		orderId: 24,
		altText: "grauOvalSilberKette",
	},
	{
		path: kartenmotiv,
		orderId: 43,
		altText: "kartenmotiv",
	},
	{
		path: kroenchen,
		orderId: 9,
		altText: "kroenchen",
	},
	{
		path: nadelkissen,
		orderId: 11,
		altText: "nadelkissen",
	},
	{
		path: ohrringe2,
		orderId: 12,
		altText: "ohrringe2",
	},
	{
		path: osterDeko,
		orderId: 42,
		altText: "osterDeko",
	},
	{
		path: puschelRinge,
		orderId: 28,
		altText: "puschelRinge",
	},
	{
		path: rosaFilzKette,
		orderId: 39,
		altText: "rosaFilzKette",
	},
	{
		path: schal,
		orderId: 10,
		altText: "schal",
	},
	{
		path: schalenBlau,
		orderId: 20,
		altText: "schalenBlau",
	},
	{
		path: stulpen,
		orderId: 4,
		altText: "stulpen",
	},
	{
		path: uhrenkette1,
		orderId: 2,
		altText: "uhrenkette1",
	},
	{
		path: uhrenkette2,
		orderId: 1,
		altText: "uhrenkette2",
	},
	{
		path: verschiedeneKetten,
		orderId: 34,
		altText: "verschiedeneKetten",
	},
];

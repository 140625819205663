import React from "react";
import { ComponentContainerButtonsLayout, IButtonData } from "../../../component/container/component-container-buttons-layout";
import blaueSchalen from "./pictures/blaueSchalen.jpg";

const linkPrefix: string = "/materialien/keramik";

const buttonData: IButtonData[] = [
	{
		path: linkPrefix + "/mobiles-geschirr",
		text: "mobiles geschirr"
	},
	{
		path: linkPrefix + "/schalen",
		text: "schalen"
	}
];

export class KeramikClass extends React.Component<any, any> {
	public render() {
		return (
			<ComponentContainerButtonsLayout buttonData={buttonData} title={"keramik"}>
				<img className={"responsiveImg"} src={blaueSchalen} alt={"Arbeitsstudie Kaffetasste"}/>
			</ComponentContainerButtonsLayout>
		);
	}
}

import React from "react";
import { ComponentContainerPictureLayout } from "../../../component/container/component-container-picture-layout";
import { IPictureItem } from "../../../component/grid-gallery/grid-gallery";
import silberRing from "./pictures/silberRing.jpg";
import silberUndFilzKugelKette from "./pictures/silberUndFIilzKugelKette.jpg";

const pictureList: IPictureItem[] = [
	{
		path: silberRing,
		orderId: 3,
		altText: "silber Ring ",
	},
	{
		path: silberUndFilzKugelKette,
		orderId: 1,
		altText: "silber Und Filz Kugel Kette ",
	},
];

const text: JSX.Element = (
	<p>Ergebnisse des Goldschmiede-workshops in St. Vith-Sommeratelier &ldquo;
		Kunst im Dorf&rdquo; bei Usch Jacobi, Belgien</p>
);

export class PageSchmuck extends React.Component {
	public render() {
		return (
			<ComponentContainerPictureLayout title={"schmuck"} pictureList={pictureList} text={text} enableBottomPicture={true}/>
		);
	}
}

import React, { Component } from "react";
import { Table, TableProps } from "react-bootstrap";
import { IMarktListItem } from "./component-mark-list-items";

interface IComponentMarktListProps extends TableProps {
	marktItemList: IMarktListItem[];
	maxWidth?: string;
	className?: string;
}

export class ComponentMarktList extends Component<IComponentMarktListProps, any> {
	
	private static createMarktListItem(item: IMarktListItem): JSX.Element {
		if (item.noLink) {
			return (
				<React.Fragment>
					<tr>
						<td>{item.date}</td>
					</tr>
					<tr>
						<td>{item.town} - {item.name}</td>
					</tr>
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					<tr>
						<td>{item.date}</td>
					</tr>
					<tr>
						<td>{item.town} - <a href={item.link} target={"blank"}>{item.name}</a></td>
					</tr>
				</React.Fragment>
			);
		}
	}
	
	public render() {
		return (
			<div>
				<Table striped bordered hover responsive borderless={true} id={"tableMarkt"} className={this.props.className} style={{maxWidth: this.props.maxWidth}}>
					<tbody>
					{this.createMarkList(this.props.marktItemList)}
					</tbody>
				</Table>
			</div>
		);
	}
	
	private createMarkList(data: IMarktListItem[]): JSX.Element[] {
		return data.map(item => ComponentMarktList.createMarktListItem(item));
	}
}

import React, { Component } from "react";
import { ComponentContainerPictureLayout } from "../../../component/container/component-container-picture-layout";
import { IPictureItem } from "../../../component/grid-gallery/grid-gallery";
import keramikInGras from "./pictures/keramikInGras.jpg";
import schuesseln from "./pictures/schuesseln.jpg";

const pictureList: IPictureItem[] = [
	{
		path: keramikInGras,
		altText: "keramikInGras",
		orderId: 1
	},
	{
		path: schuesseln,
		altText: "schuesseln",
		orderId: 2
	}
];

const text: JSX.Element = (
	<React.Fragment>
		<p className="bodytext">DesignPreis MAGGI-edition 1995</p>
		<p className="bodytext">f&uuml;r 2 Personen zum Picknick, zur Party, in die Ferienwohnung bruchsicher verpackt tragbar</p>
	</React.Fragment>

);

export class MobilesGeschirrClass extends Component {
	public render() {
		return (
			<ComponentContainerPictureLayout title={"mobiles geschirr"} pictureList={pictureList} text={text} enableBottomPicture={false}/>
		);
	}
}

import React from "react";
import { ComponentRouting } from "../routing/component-routing";
import { routeList } from "../routing/component-routing-list";
// import { ComponentBreadcrumb } from "./breadcrumbs/component-breadcrumb";
import { ComponentFooter } from "./footer/component-footer";
import { ComponentNavbar } from "./navbar/component-navbar";
import "./app.css";

export class App extends React.Component<any, any> {
	
	public render() {
		
		return (
			
			<React.Fragment>
				<ComponentNavbar/>
				{/*<ComponentBreadcrumb/>*/}
				<ComponentRouting routeList={routeList}/>
				<ComponentFooter/>
			</React.Fragment>
		
		);
	}
	
}

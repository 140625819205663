import React from "react";
import { ComponentContainerPictureLayout } from "../../../component/container/component-container-picture-layout";
import { IPictureItem } from "../../../component/grid-gallery/grid-gallery";
import dreiKannen from "./pictures/dreiKannen.jpg";
import services from "./pictures/services.jpg";
import servicesWeiss from "./pictures/servicesWeiß.jpg";

const pictureList: IPictureItem[] = [
	{
		path: services,
		orderId: 1,
		altText: "services"
	},
	{
		path: servicesWeiss,
		orderId: 2,
		altText: "servicesWeiß"
	},
	{
		path: dreiKannen,
		orderId: 3,
		altText: "dreiKannen"
	}
];

const text: JSX.Element = (
	<React.Fragment>
		<p>&quot;Das Gesicht eines<br/>Services wird durch die<br/><strong>Kanne</strong> bestimmt&quot; </p>
	</React.Fragment>
);

export class PagePorzellanKaffee extends React.Component {
	public render() {
		return (
			<div>
				<ComponentContainerPictureLayout title={"Kaffeeset"} pictureList={pictureList} text={text} enableBottomPicture={true}/>
			</div>
		);
	}
}

import React, { Component } from "react";
import { ComponentContainerInner } from "../../component/container/component-container-inner";
import { ComponentContainerMain } from "../../component/container/component-container-main";

const text: JSX.Element = (
	<div>
		<div className="sp3">
			<div id="c22" className="csc-default">
				<div className="csc-textpic-text">
					<p className="bodytext">birgit gillen</p>
					<p className="bodytext">bg design<br/>Weiherstrasse 13<br/><br/>52156 Monschau<br/>mobil 0175 1575195</p>
					<p className="bodytext">mail <a>info(at)birgitgillen&middot;de</a></p>
					<p className="bodytext">&nbsp;</p>
				</div>
			</div>
			<div id="c144" className="csc-default">
				<div>
					<p className="align-left"><strong>Disclaimer &ndash; rechtliche Hinweise</strong></p>
					<p className="align-left">&nbsp;</p>
				</div>
				<div>
					<p className="bodytext"><strong>1. Haftungsbeschr&auml;nkung<br/></strong></p>
				</div>
				<div>
					<p className="bodytext">Die Inhalte dieser Website werden mit gr&ouml;&szlig;tm&ouml;glicher Sorgfalt erstellt. Der Anbieter &uuml;bernimmt jedoch keine
						Gew&auml;hr
						f&uuml;r die Richtigkeit, Vollst&auml;ndigkeit und Aktualit&auml;t der bereitgestellten Inhalte. Die Nutzung der Inhalte der Website erfolgt auf eigene
						Gefahr
						des Nutzers. Namentlich gekennzeichnete Beitr&auml;ge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters wieder. Mit der
						reinen
						Nutzung der Website des Anbieters kommt keinerlei Vertragsverh&auml;ltnis zwischen dem Nutzer und dem Anbieter zustande.</p>
					<p className="bodytext">&nbsp;</p>
				</div>
				<div>
					<p className="bodytext"><strong>2. Externe Links<br/></strong></p>
				</div>
				<div>
					<p className="bodytext">Diese Website enth&auml;lt Verkn&uuml;pfungen zu Websites Dritter (&bdquo;externe Links&ldquo;). Diese Websites unterliegen der Haftung
						der
						jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verkn&uuml;pfung der externen Links die fremden Inhalte daraufhin &uuml;berpr&uuml;ft, ob etwaige
						Rechtsverst&ouml;&szlig;e bestehen. Zu dem Zeitpunkt waren keine Rechtsverst&ouml;&szlig;e ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle
						und
						zuk&uuml;nftige Gestaltung und auf die Inhalte der verkn&uuml;pften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter
						dem
						Verweis oder Link liegenden Inhalte zu Eigen macht. Eine st&auml;ndige Kontrolle der externen Links ist f&uuml;r den Anbieter ohne konkrete Hinweise auf
						Rechtsverst&ouml;&szlig;e nicht zumutbar. Bei Kenntnis von Rechtsverst&ouml;&szlig;en werden jedoch derartige externe Links unverz&uuml;glich
						gel&ouml;scht.</p>
				</div>
				<div>
					<p className="bodytext"><strong><br/>3. Urheber- und Leistungsschutzrechte<br/></strong></p>
				</div>
				<div>
					<p className="bodytext">Die auf dieser Website ver&ouml;ffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen
						Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers.
						Dies
						gilt insbesondere f&uuml;r Vervielf&auml;ltigung, Bearbeitung, &Uuml;bersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken
						oder
						anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte Vervielf&auml;ltigung oder
						Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads f&uuml;r den
						pers&ouml;nlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.</p>
				</div>
				<div>
					<p className="bodytext">Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zul&auml;ssig.</p>
				</div>
				<div>
					<p className="bodytext"><strong><br/>4. Datenschutz<br/></strong></p>
				</div>
				<div>
					<p className="bodytext">Durch den Besuch der Website des Anbieters k&ouml;nnen Informationen &uuml;ber den Zugriff (Datum, Uhrzeit, betrachtete Seite)
						gespeichert
						werden. Diese Daten geh&ouml;ren nicht zu den personenbezogenen Daten, sondern sind anonymisiert. Sie werden ausschlie&szlig;lich zu statistischen Zwecken
						ausgewertet. Eine Weitergabe an Dritte, zu kommerziellen oder nichtkommerziellen Zwecken, findet nicht statt.</p>
				</div>
				<div>
					<p className="bodytext">Der Anbieter weist ausdr&uuml;cklich darauf hin, dass die Daten&uuml;bertragung im Internet (z.B. bei der Kommunikation per E-Mail)
						Sicherheitsl&uuml;cken aufweisen und nicht l&uuml;ckenlos vor dem Zugriff durch Dritte gesch&uuml;tzt werden kann.</p>
				</div>
				<div>
					<p className="bodytext">Die Verwendung der Kontaktdaten des Impressums zur gewerblichen Werbung ist ausdr&uuml;cklich nicht erw&uuml;nscht, es sei denn der
						Anbieter
						hatte zuvor seine schriftliche Einwilligung erteilt oder es besteht bereits eine Gesch&auml;ftsbeziehung. Der Anbieter und alle auf dieser Website genannten
						Personen widersprechen hiermit jeder kommerziellen Verwendung und Weitergabe ihrer Daten.</p>
				</div>
				<div>
					<p className="bodytext"><strong><br/>Personenbezogene Daten<br/></strong></p>
				</div>
				<div>
					<p className="bodytext">Sie k&ouml;nnen unsere Webseite ohne Angabe personenbezogener Daten besuchen. Soweit auf unseren Seiten personenbezogene Daten (wie
						Name,
						Anschrift oder E-Mail Adresse) erhoben werden, erfolgt dies, soweit m&ouml;glich, auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdr&uuml;ckliche
						Zustimmung nicht an Dritte weitergegeben. Sofern zwischen Ihnen und uns ein Vertragsverh&auml;ltnis begr&uuml;ndet, inhaltlich ausgestaltet oder
						ge&auml;ndert
						werden soll oder Sie an uns eine Anfrage stellen, erheben und verwenden wir personenbezogene Daten von Ihnen, soweit dies zu diesen Zwecken erforderlich ist
						(Bestandsdaten). Wir erheben, verarbeiten und nutzen personenbezogene Daten soweit dies erforderlich ist, um Ihnen die Inanspruchnahme des Webangebots zu
						erm&ouml;glichen (Nutzungsdaten). S&auml;mtliche personenbezogenen Daten werden nur solange gespeichert wie dies f&uuml;r den geannten Zweck (Bearbeitung
						Ihrer
						Anfrage oder Abwicklung eines Vertrags) erforderlich ist. Hierbei werden steuer- und handelsrechtliche Aufbewahrungsfristen ber&uuml;cksichtigt. Auf
						Anordnung
						der zust&auml;ndigen Stellen d&uuml;rfen wir im Einzelfall Auskunft &uuml;ber diese Daten (Bestandsdaten) erteilen, soweit dies f&uuml;r Zwecke der
						Strafverfolgung, zur Gefahrenabwehr, zur Erf&uuml;llung der gesetzlichen Aufgaben der Verfassungsschutzbeh&ouml;rden oder des Milit&auml;rischen
						Abschirmdienstes oder zur Durchsetzung der Rechte am geistigen Eigentum erforderlich ist.</p>
				</div>
				<div>
					<p className="bodytext"><strong><br/>Datenschutzerkl&auml;rung f&uuml;r den Webanalysedienst Google Analytics<br/></strong></p>
				</div>
				<div>
					<p className="bodytext">Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. (&bdquo;Google&ldquo;). Google Analytics verwendet
						sog. &bdquo;Cookies&ldquo;, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie
						erm&ouml;glichen.
						Die durch den Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den
						USA &uuml;bertragen
						und dort gespeichert. Wir haben die IP-Anonymisierung aktiviert. Auf dieser Webseite wird Ihre IP-Adresse von Google daher innerhalb von Mitgliedstaaten der
						Europ&auml;ischen Union oder in anderen Vertragsstaaten des Abkommens &uuml;ber den Europ&auml;ischen Wirtschaftsraum zuvor gek&uuml;rzt. Nur in
						Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server von Google in den USA &uuml;bertragen und dort gek&uuml;rzt. Im Auftrag des Betreibers dieser
						Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports &uuml;ber die Websiteaktivit&auml;ten
						zusammenzustellen
						und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegen&uuml;ber dem Websitebetreiber zu erbringen. Die im Rahmen
						von
						Google Analytics von Ihrem Browser &uuml;bermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengef&uuml;hrt. Sie k&ouml;nnen die
						Speicherung
						der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
						nicht s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich werden nutzen k&ouml;nnen. Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch
						das
						Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
						verhindern, indem sie das unter dem folgenden Link verf&uuml;gbare Browser-Plugin herunterladen und installieren: <a
							href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noreferrer">tools.google.com/dlpage/gaoptout</a></p>
				</div>
				<div>
					<p className="bodytext"><strong><br/>Auskunftsrecht<br/></strong></p>
				</div>
				<div>
					<p className="bodytext">Sie haben das jederzeitige Recht, sich unentgeltlich und unverz&uuml;glich &uuml;ber die zu Ihrer Person erhobenen Daten zu erkundigen.
						Sie
						haben das jederzeitige Recht, Ihre Zustimmung zur Verwendung Ihrer angegeben pers&ouml;nlichen Daten mit Wirkung f&uuml;r die Zukunft zu widerrufen. Zur
						Auskunftserteilung wenden Sie sich bitte an den Anbieter unter den Kontaktdaten im Impressum.</p>
				</div>
				<div>&nbsp;</div>
				<div>
					<p className="bodytext"><strong>5. Besondere Nutzungsbedingungen<br/></strong></p>
				</div>
				<div>
					<p className="bodytext">Soweit besondere Bedingungen f&uuml;r einzelne Nutzungen dieser Website von den vorgenannten Nummern 1. bis 4. abweichen, wird an
						entsprechender Stelle ausdr&uuml;cklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die besonderen Nutzungsbedingungen.<br/><br/>
					</p>
				</div>
				<div>
					<p className="bodytext">Quelle: Muster-Disclaimer von Juraforum.de in Kooperation mit Experten-Branchenbuch.de</p>
				</div>
				<div>&nbsp;</div>
			</div>
		</div>
		<div className="sp4">&nbsp;</div>
		<div className="cl">&nbsp;</div>
	</div>
);

export class PageImpressum extends Component {
	public render() {
		return (
			<ComponentContainerMain title={"impressum"}>
				<ComponentContainerInner>
					<div style={{textAlign: "right"}}>
						{text}
					</div>
				</ComponentContainerInner>
			</ComponentContainerMain>
		);
	}
}

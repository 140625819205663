import React from "react";
import { Route, Switch } from "react-router-dom";
import { LandingPage } from "../pages/landing-page/page-landing-page";
import { PageNoMatch } from "../pages/page-no-match";
import { IRouteList } from "./component-routing-list";

interface IRoutingContainerProps {
	routeList: IRouteList[];
}

export class ComponentRouting extends React.Component <IRoutingContainerProps> {
	
	public constructor(props: IRoutingContainerProps) {
		super(props);
		
	}
	
	private static createRoute(path: IRouteList["path"], component: IRouteList["component"]): JSX.Element {
		return (
			<Route path={path} exact component={component}/>
		);
	}
	
	public render() {
		return (
			<Switch>
				<Route exact path={"/"} component={LandingPage}/>
				{this.createRoutes(this.props.routeList)}
				<Route component={PageNoMatch}/>
			</Switch>
		);
	}
	
	private createRoutes(routeList: IRouteList[]): JSX.Element[] {
		return routeList.map(data => ComponentRouting.createRoute(data.path, data.component));
	}
}

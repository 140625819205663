import React from "react";
import { Row } from "react-bootstrap";
import { ComponentContainerButtonsLayout, IButtonData } from "../../../component/container/component-container-buttons-layout";
import haarband from "./pictures/haarband.jpg";

const PREFIX = "/materialien/filz";

const buttonData: IButtonData[] = [
	{
		text: "filzschmuck",
		path: PREFIX + "/filzschmuck"
	},
	{
		text: "filzdekoration",
		path: PREFIX + "/filzdekoration"
	},
	{
		text: "kinderfilz",
		path: PREFIX + "/kinderfilz",
	},
];

export class FilzClass extends React.Component<any, any> {
	public render() {
		return (
			<ComponentContainerButtonsLayout title={"filz"} buttonData={buttonData}>
					<img className={"responsiveImg"}
						 src={haarband}
						 alt={"haarband"}/>
					<div>
						<p>Filzen ist nicht nur eine ganz alte Technik Wolle zu verarbeiten,
							sondern auch eine wunderbare M&ouml;glichkeit mit Wolle zu malen und zu
							experimentieren&nbsp;!</p>
						<p>&nbsp;</p>
					</div>
			</ComponentContainerButtonsLayout>
		);
	}
}

import React, { Component } from "react";
import { GridGallery, IPictureItem } from "../../../component/grid-gallery/grid-gallery";
import klavierHocker from "./pictures/klavierHocker.jpg";
import klavierHocker2 from "./pictures/klavierHocker2.jpg";
import vase from "./pictures/vase.jpg";

const pictureList: IPictureItem[] = [
	{
		path: klavierHocker2,
		orderId: 0,
		altText: "klavier Hocker2 ",
	},
	{
		path: klavierHocker,
		orderId: 1,
		altText: "klavier Hocker ",
	},
	{
		path: vase,
		orderId: 2,
		altText: "vase ",
	},
];

export class PageFilzDekoration extends Component {
	public render() {
		return (
			<GridGallery title={"filzdekoration"} pictureList={pictureList} colCount={3}/>
		);
	}
}

import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ComponentContainerMain } from "./component-container-main";

export interface IButtonData {
	text: string;
	path: string;
}

interface IComponentContainerButtonsLayoutProps {
	title: string;
	buttonData: IButtonData[];
}

export class ComponentContainerButtonsLayout extends Component <IComponentContainerButtonsLayoutProps> {
	
	public constructor(props: IComponentContainerButtonsLayoutProps) {
		super(props);
	}
	
	public render() {
		return (
			<ComponentContainerMain title={this.props.title}>
				<Row>
					<Col md={4}>
						{this.createButtons(this.props.buttonData)}
					</Col>
					<Col md={8}>
						{this.props.children}
					</Col>
				</Row>
			</ComponentContainerMain>
		);
	}
	
	private createButtons(data: IButtonData[]): JSX.Element[] {
		return data.map(item => this.createButton(item));
	}
	
	private createButton(item: IButtonData): JSX.Element {
		return (
			<Row className={"respButtonPos"}>
				<Link to={item.path}>
					<Button id={"linkButton"}>
						{item.text}
					</Button>
				</Link>
			</Row>
		);
	}
}

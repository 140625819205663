import React from "react";
import { Col, Row } from "react-bootstrap";
import { ComponentLightboxWrapper } from "../grid-gallery/component-lightbox-wrapper";
import { IPictureItem } from "../grid-gallery/grid-gallery";
import { ComponentContainerInner } from "./component-container-inner";
import { ComponentContainerMain } from "./component-container-main";

interface IComponentContainerFourPictureLayoutProps {
	title: string;
	pictureList: IPictureItem[];
	text: JSX.Element;
	enableBottomPicture: boolean;
	maxWidthRightPictureColumn?: string;
}

export class ComponentContainerPictureLayout extends React.Component <IComponentContainerFourPictureLayoutProps, any> {
	
	/**
	 * Sorts array depending on orderId
	 *
	 * @param data Array of picture Items with src, orderId and alt-text
	 */
	private static sortByOrder(data: IPictureItem[]): IPictureItem[] {
		return data.sort((a, b) => a.orderId - b.orderId);
	}
	
	private static createPictureItem(item: IPictureItem, id: string, maxWidthRightPictureColumn?: string): JSX.Element {
		return (
			<div className={"pictureLayout"}>
				<div className="item">
					<a href={item.path}>
						<img className="image"
							 id={id}
							 src={item.path}
							 alt={item.altText}/>
					</a>
				</div>
			</div>
		);
	}
	
	public render() {
		return (
			<React.Fragment>
				<ComponentContainerMain title={this.props.title}>
					<ComponentLightboxWrapper>
						{this.createFourPictureLayout(this.props.pictureList, this.props.text)}
					</ComponentLightboxWrapper>
				</ComponentContainerMain>
			
			</React.Fragment>
		);
	}
	
	private createFourPictureLayout(pictures: IPictureItem[], text: JSX.Element) {
		const orderedPictures: IPictureItem[] = ComponentContainerPictureLayout.sortByOrder(pictures);
		return (
			<ComponentContainerInner>
				<Row>
					<Col id={"textCol"}>
						<div>
							{text}
						</div>
					</Col>
					<Col id={"twoPictureCol"} xs={12} sm={5}>
						{this.createPictureColumn(this.props.enableBottomPicture, orderedPictures, this.props.maxWidthRightPictureColumn)}
					</Col>
				</Row>
				<Row>
					<Col id={"bottomPictureCol"} xs={12} sm={7} style={{justifyContent: "end"}}>
						{this.createBottomPictureItem(this.props.enableBottomPicture, orderedPictures)}
					</Col>
					<Col xs={12} md={5}/>
				</Row>
			</ComponentContainerInner>
		);
	}
	
	private createPictureColumn(enableBottomPicture: boolean, orderedPictures: IPictureItem[], maxWidthRightPictureColumn: string | undefined) {
		if (enableBottomPicture) {
			// Get every but last picture
			return orderedPictures.slice(0, -1).map(item => ComponentContainerPictureLayout.createPictureItem(item, "pictureColumn", maxWidthRightPictureColumn));
		} else {
			// Get every picture in column
			return orderedPictures.map(item => ComponentContainerPictureLayout.createPictureItem(item, "picureColumn"));
		}
	}
	
	/**
	 * Create only the last picture
	 * @param enableBottomPicture Boolean set by user
	 * @param orderedPictures Already prepared picture Array
	 */
	private createBottomPictureItem(enableBottomPicture: boolean, orderedPictures: IPictureItem[]) {
		if (this.props.enableBottomPicture) {
			return orderedPictures.slice(-1).map(item => ComponentContainerPictureLayout.createPictureItem(item, "bottomPicture"));
		} else {
			return (<React.Fragment/>);
		}
	}
}

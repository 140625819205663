import React, { Component } from "react";

export class KontaktClass extends Component {
	public render() {
		return (
			<div>
			
			</div>
		);
	}
}
